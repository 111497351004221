//import settings
@import "../skeleton/settings";

//---------------release item info page-----------------------//

#readMore {
  & .page_section {
    max-width: 1000px !important;
  }
}

.album_platforms {
  @extend .container;
  margin-top: $spacing4;
}

.album_disp {
  @extend .container_row--center;
  margin: $spacing3 auto $spacing1;
}

.album-title {
  font-variant: small-caps;
}

.album_cover {
  box-shadow: $dropShadowBlack;
}
.album-cover {
  width: 100%;
}

.album_info {
  max-width: $pageWSmall;
  margin-top: 0;
  padding: $spacing1;
}

.album_description {
  margin-top: $spacing3;
}

.albumInfo-item {
  margin: 0 0.5rem 2rem;
  font-size: 1rem;
}

.album_spotify {
  display: flex;
  flex-shrink: 1;
  margin-left: 2rem;
  & > iframe {
    height: 100%;
    width: 100%;
  }
}

.album_youtube {
  display: flex;
  flex-shrink: 1;
  width: fit-content;
  max-height: 400px;
}

.album_media {
  margin-top: 0;
  padding: $spacing1;
}

.album_mediaList {
  @extend .container_row--center;
}

//---------------[Media Queries for different screen sizes]-----------------//

@media only screen {
  @media (max-width: 1110px) {
    .album_spotify {
      margin-left: 0;
      margin-top: 2rem;
      height: 40vh;
      max-height: 500px;
      width: 100%;
      max-width: 640px;
    }
    .album_youtube {
      display: flex;
      justify-content: center;
      width: 100%;
      flex-grow: 1;
    }
  }
}